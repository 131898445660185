import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import DataContext from '../../config/DataContext';
import ReactMarkdown from 'react-markdown';
import ImageContainer from '../../components/ImageContainer';
import CustomCarousel from '../../components/CustomCarousel';
import VideoEmbed from '../../components/VideoEmbed';
import NoImgList from '../../components/NoImgList';

const Work = () => {
  const { workId } = useParams();
  const data = useContext(DataContext);
  const work = data.works.find(work => work.id == workId);
  // const workMedias = work.medias;
  // const workLinks = work.works_link;
  const sortedMedias = work.medias.sort((a, b) => a.name.localeCompare(b.name));
  const sortedMediasCaptions = [];
  sortedMedias.map((media) => media.caption ? sortedMediasCaptions.push(media.caption) : null);
  const sortedWorks = data.works.sort((a, b) => b.year - a.year);
  const [nextWork, setNextWork] = useState();
  const [previousWork, setPreviousWork] = useState();
  const [isCarouselActive, setIsCarouselActive] = useState(false);
  const [activeMedia, setActiveMedia] = useState(sortedMedias.length >= 1 ? (sortedMedias[0].url) : null);
  const history = useHistory();

  useEffect(() => {
    data.resetInputValue()
  }, []);

  useEffect(() => {
    findPreviousWork();
    findNextWork();
  }, [workId]);

  const findPreviousWork = () => {
    const indexOfCurrentWork = sortedWorks.indexOf(work);
    if (indexOfCurrentWork === 0) {
      setPreviousWork(sortedWorks[sortedWorks.length - 1])
    } else {
      setPreviousWork(sortedWorks[indexOfCurrentWork - 1])
    };
  };

  const findNextWork = () => {
    const indexOfCurrentWork = sortedWorks.indexOf(work);
    if (indexOfCurrentWork === sortedWorks.length - 1) {
      setNextWork(sortedWorks[0])
    } else {
      setNextWork(sortedWorks[indexOfCurrentWork + 1])
    };
  };

  const handleNavigation = (e) => {
    if (e.keyCode === 37) {
      history.push(`/oeuvre&id=${nextWork.id}&titre=${nextWork.slug}`);
    } else if (e.keyCode === 39) {
      history.push(`/oeuvre&id=${previousWork.id}&titre=${previousWork.slug}`);
    }
  }

  const openCarousel = (media) => {
    setActiveMedia(media);
    setIsCarouselActive(true);
  };

  const closeCarousel = () => {
    setIsCarouselActive(false)
  };

  const updateMediaActive = (media) => {
    setActiveMedia(media)
  };

  if (work == undefined) {
    return (
      <Error404 />
    )
  }

  return (
    <>
      {activeMedia != null ? (
        <CustomCarousel
          medias={sortedMedias}
          actualWork={work}
          isCarouselActive={isCarouselActive}
          activeMedia={activeMedia}
          closeCarousel={closeCarousel}
          updateMediaActive={updateMediaActive}
        />
      ) : (null)}
      <div
        className="container__switch--page grid--2"
        onKeyDown={(e) => handleNavigation(e)}
        tabIndex="0"
      >
        <div className="section--left item">
          <div className="container__head">
            <div className="container__sticky">
              <h1 className="page__title">
                {work.name}
                {work.number ? <span> {work.number}</span> : null}
              </h1>
              {work.subname ? (
                <h2 className="page__subtitle">{work.subname}</h2>
              ) : (null)}
              {work.work_tags ?
                <ul className="container__work--type">
                  {work.work_tags
                    .sort((a, b) => a.order - b.order)
                    .map((tag) =>
                      <Link
                        to={`/&categorie=${tag.order}&annee=*`}
                        key={uuidv4()}
                      >
                        <li
                          className="work--type"
                          key={uuidv4()}
                        >
                          {tag.name}
                        </li>
                      </Link> 
                    )
                  }
                </ul>
              : null}
              {work.year ?
                <div className="container__work--year">
                  <Link to={`/&categorie=*&annee=${work.year}`}>
                    <div
                      className="work--year"
                    >
                      {work.year}
                    </div>
                  </Link>
                </div>
              : null}
            </div>
          </div>
          <div className="container__content--works--texts ">
            <div className="content not--index">
              {work.medium ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      medium
                    </div>
                  </div>
                  <ReactMarkdown className="content-md">{work.medium}</ReactMarkdown>
                </div>
              : null}
              {work.size ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      dimensions
                    </div>
                  </div>
                  <ReactMarkdown className="content-md">{work.size}</ReactMarkdown>
                </div>
              : null}
              {work.edition !== null && work.edition !== "" &&
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      édition
                    </div>
                  </div>
                  <ReactMarkdown className="content-md">{work.edition}</ReactMarkdown>
                </div>
              }
              {work.location ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      lieu
                    </div>
                  </div>
                  <ReactMarkdown className="content-md">{work.location}</ReactMarkdown>
                </div>
              : null}
              {work.description ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      description
                    </div>
                  </div>
                  <ReactMarkdown className="content-md">{work.description}</ReactMarkdown>
                </div>
              : null}
              {work.exhibition ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      exposition
                    </div>
                  </div>
                  <ReactMarkdown className="content-md">{work.exhibition}</ReactMarkdown>
                </div>
              : null}
              {work.date ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      dates
                    </div>
                  </div>
                  <div className="content-md uppercase">{work.date}</div>
                </div>
              : null}
              {work.direction ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      direction
                    </div>
                  </div>
                  <div className="content">{work.direction}</div>
                </div>
              : null}
              {work.music ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      musique
                    </div>
                  </div>
                  <div className="content">{work.music}</div>
                </div>
              : null}
              {work.duration ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      durée
                    </div>
                  </div>
                  <div className="content">{work.duration}</div>
                </div>
              : null}
              {work.link ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      liens
                    </div>
                  </div>
                  <ReactMarkdown className="content-md link" linkTarget="_blank">{work.link}</ReactMarkdown>
                </div>
              : null}
              {work.exhibitions_link.length >= 1 ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      {work.exhibitions_link.length === 1 ? "exposition associée" : "expositions associées"}
                    </div>
                  </div>
                  <ul className="content">
                    {work.exhibitions_link
                      .sort((a, b) => a.number - b.number)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .sort((a, b) => b.year - a.year)
                      .map((linkedWork) =>
                      <li className="type" key={uuidv4()}>
                        <Link to={`/oeuvre&id=${linkedWork.id}&titre=${linkedWork.slug}`}>
                          {linkedWork.name} {linkedWork.number ? (<span> {linkedWork.number}</span>) : (null)} ({linkedWork.year})
                        </Link>
                      </li>)
                    }
                  </ul>
                </div>
              : null}
              {work.works_link.length >= 1 ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      {work.works_link.length === 1 ? "œuvre associée" : "œuvres associées"}
                    </div>
                  </div>
                  <ul className="content">
                    {work.works_link
                      .sort((a, b) => a.number - b.number)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .sort((a, b) => b.year - a.year)
                      .map((linkedWork) =>
                      <li className="type" key={uuidv4()}>
                        <Link to={`/oeuvre&id=${linkedWork.id}&titre=${linkedWork.slug}`}>
                          {linkedWork.name} {linkedWork.number ? (<span> {linkedWork.number}</span>) : (null)} ({linkedWork.year})
                        </Link>
                      </li>)
                    }
                  </ul>
                </div>
              : null}
              {work.texts_link.length >= 1 ?
                <div className="show-item">
                  <div className="label">
                    <div className="sticky--work">
                      {work.texts_link.length === 1 ? "texte associé" : "textes associés"}
                    </div>
                  </div>
                  <ul className="content">
                    {work.texts_link
                      .sort((a, b) => b.year - a.year)
                      .map((linkedText) =>
                      <li className="type" key={uuidv4()}>
                        <Link to={`/texte&id=${linkedText.id}&titre=${linkedText.slug}`}>
                          {linkedText.name} ({linkedText.year})
                        </Link>
                      </li>)
                    }
                  </ul>
                </div>
              : null}
              {sortedMediasCaptions.length >= 1 ?
              <div className="show-item">
                <div className="label">
                  <div className="sticky--work">
                    légendes
                  </div>
                </div>
                <ul className="content">
                  {sortedMedias.map((media, index) => 
                    <li
                      className="caption--link"
                      key={uuidv4()}
                      onClick={() => openCarousel(media)}
                    >
                      {media.caption ? (
                        <span>{index + 1}. {media.caption}</span>
                      ) : null}
                    </li>)
                  }
                </ul>
              </div>
              : null}
            </div>
          </div>

          {/* <div className="nav--bottom">
            {nextWork ? (
              <Link className="item--previous" to={`/oeuvres&type=${work.work_tags[0].name}`}>
                {work.work_tags[0].name}
              </Link>
            ) : (
              null
            )}
            {previousWork ? (
              <Link className="item--next" to={`/oeuvres&année=${work.year}`}>
                œuvres de {work.year}
              </Link>
            ) : (
              null
            )}
          </div> */}
        </div>

        <div className="section--right work">
          <div className="container__img--overflow">
            {work.video_embed_code ? (
              <VideoEmbed video={work.video_embed_code} />
            ) : (null)}
            <div className={`container__all__imgs grid--${work.image_grid_column}`}>
              {sortedMedias.length >= 1 ? sortedMedias.map((media) =>
                <div
                  className="toggle--Carousel"
                  onClick={() => openCarousel(media)}
                  key={uuidv4()}
                >
                  <ImageContainer
                    media={media}
                  />
                </div>
                ) : (
                <NoImgList />
              )}
            </div>
          </div>

          {work.photographs ?
            <div className="container__photographs">
              <div className="label">photos</div>
              <div className="content">{work.photographs}</div>
            </div>
          : null}
        </div>
        
      </div>
    </>
  );
};

export default Work;
