import React from 'react';
import './customCarousel.scss';
import Cross from '../../assets/svg/Cross';
import ArrowPrevious from '../../assets/svg/ArrowPrevious';
import ArrowNext from '../../assets/svg/ArrowNext';

const CustomCarousel = ({ 
  medias,
  actualWork,
  isCarouselActive,
  activeMedia,
  closeCarousel,
  updateMediaActive }) => {

  const FindPreviousMedia = () => {
    const indexOfCurrentMedia = medias.indexOf(activeMedia);
    if (indexOfCurrentMedia === medias.length - 1) {
      updateMediaActive(medias[0])
    } else {
      updateMediaActive(medias[indexOfCurrentMedia + 1])
    };  
  };

  const FindNextMedia = () => {
    const indexOfCurrentMedia = medias.indexOf(activeMedia);
    if (indexOfCurrentMedia === 0) {
      updateMediaActive(medias[medias.length - 1])
    } else {
      updateMediaActive(medias[indexOfCurrentMedia - 1])
    };    
  };

  return (
    <div className={`container__customCarousel--all ${isCarouselActive ? '' : 'none'}`}>

      <div className={`container__img--active ${medias.length > 1 ? 'multiple--imgs' : null}`}>
        {medias.length > 1 ? (
          <div
            className="container__previous--cta"
            onClick={FindNextMedia}
          >
            <ArrowPrevious />
          </div>
        ) : null}
        <div className="container--img">
          <img
            className="img--active"
            src={activeMedia.url}
            alt={`Alain Séchas œuvre ${actualWork.name}`}
          />
        </div>
        {medias.length > 1 ? (
          <div
            className="container__next--cta"
            onClick={FindPreviousMedia}
          >
            <ArrowNext />
          </div>
        ) : null}
      </div>

      <div className="container__caption--active">
        <div className="actualWork--infos">
          <p className="year">{actualWork.year}</p>
          <p className="name">{actualWork.name}</p>
        </div>
        <div className="actualWork--secondary">
          {activeMedia.caption ? (
            <p className="caption">{medias.indexOf(activeMedia) + 1}. {activeMedia.caption}</p>
          ) : (
            <p className="caption"></p>
          )}
          <p className="count">{medias.indexOf(activeMedia) + 1}/{medias.length}</p>
        </div>
      </div>
      <div className="container__caption--active mobile">
        {activeMedia.caption ? (
          <div className="actualWork--secondary">
            <p className="caption">{medias.indexOf(activeMedia) + 1}. {activeMedia.caption}</p>
          </div>
        ) : (null)}
        <div className={medias.length > 1 ? "container__nav--slider--mobile" : "container__nav--slider--mobile uniq"}>
          <p className="count">{medias.indexOf(activeMedia) + 1}/{medias.length}</p>
          {medias.length > 1 ? (
            <div
              className="container__previous--cta--mobile"
              onClick={FindNextMedia}
            >
              <ArrowPrevious />
            </div>
          ) : (
            null
          )}
          {medias.length > 1 ? (
            <div
              className="container__next--cta--mobile"
              onClick={FindPreviousMedia}
            >
              <ArrowNext />
            </div>
          ) : (
            null
          )}
          <div
            className="container__close--cta--mobile"
            onClick={closeCarousel}
          >
            <Cross />
          </div>
        </div>

      </div>
      <div
        className="container__close--cta"
        onClick={closeCarousel}
      >
        <Cross />
      </div>

    </div>
  );
};

export default CustomCarousel;
