import React from 'react';
import './previewImgs.scss';
import NoImgPreview from '../NoImgPreview';

const PreviewImgs = ({ activeWork }) => {

  return (
    <div className="container__preview--img">
      {activeWork.medias ? (
        activeWork.medias.length >= 1 ? (
          <img
            className="img--preview"
            src={activeWork.medias[0].url}
            alt={`Alain Séchas œuvre ${activeWork.name}`}
          />        
        ) : (
          <NoImgPreview />
        )
      ) : (
        (null)
      )}
    </div>
  );
};

export default PreviewImgs;
