import React from 'react';
import './error404.scss';
import { Link } from 'react-router-dom';

const Error404 = () => {

  return (
    <div className="container__switch--page">
      <div className="container__head">
        <h1 className="page__title uppercase">erreur 404</h1>
      </div>
      <div className="container__content--error">
        <p className="text--grey--uppercase">la page que vous recherchez n'existe pas/plus.</p>
        <Link to="/" className="link--uppercase">Retourner à l'accueil</Link>
      </div>
    </div>
  );
};

export default Error404;
