import React from "react";
import "../ImageContainer/ImageContainer.scss";

const ImageContainer = ({ media }) => {
  return (
    <div className="container__img">
      <img
        src={`${media.url}`}
        alt={`Alain Séchas œuvre ${media.caption}`}
        loading="lazy" 
      />
    </div>
  );
};

export default ImageContainer;
