import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import DataContext from '../../config/DataContext';
import ReactMarkdown from 'react-markdown';

const Texts = () => {
  const data = useContext(DataContext);
  const history = useHistory();
  const { yearId } = useParams();
  const texts = data.texts.sort((a, b) => b.year - a.year);
  const [textsToDisplay, setTextsToDisplay] = useState([]);
  const [activeText, setActiveText] = useState([]);

  useEffect(() => {
    data.resetInputValue();
  }, []);

  useEffect(() => {
    if (yearId == "*") {
      setTextsToDisplay(texts)
    } else {
      setTextsToDisplay(texts.filter((text) => text.year == yearId))
    }
  }, [yearId]);

  const handleURL = (year) => {
    if (year == yearId) {
      history.push(`/textes&annee=*`);
    } else {
      history.push(`/textes&annee=${year}`);
    }
  };

  return (
    <div className="container__switch--page grid--2">
      <div className="section--left index">
        <div className="container__head">
          <div className="container__sticky">
            <h1 className="page__title uppercase">textes</h1>
            <div className="container__filter last">
              {
                [...new Set(texts.map(ex => ex.year))]
                .map((year) => 
                  <button
                    className={year == yearId ? "filter--btn active" : "filter--btn"}
                    key={uuidv4()}
                    onClick={() => handleURL(year)}
                  >
                    {year}
                  </button>
                )
              }
            </div>
          </div>
        </div>

        <div className="container__content--works--texts index">
          <div className="nav">
            <div className="head--item">année</div>
            <div className="head--item">titre</div>
            <div className="head--item">auteur(e)</div>
          </div>
          <div className="content">
            {textsToDisplay
              .map((text) => 
              <Link
                to={`/texte&id=${text.id}&titre=${text.slug}`}
                key={uuidv4()}
                className="item" 
                onMouseEnter={() => setActiveText(text)}
                onMouseLeave={() => setActiveText([])}
              >
                <div className="item--year">
                  <div className="sticky--word">
                    {text.year}
                  </div>
                </div>
                <div className="item--title">
                    {text.name}
                    {text.subname ? 
                      (<div className="span--subname"> {text.subname}</div>) 
                      : (null)
                    }
                  </div>
                <div className="item--author">{text.author}</div>
              </Link>
            )}
          </div>
        </div>
        
      </div>

      <div className="section--right text preview">
        <div className="container__all--texts preview">
          {activeText.content ?
            <ReactMarkdown className="item--text content">{activeText.content}</ReactMarkdown>
            : null}
          {activeText.date ?
            <div className="item--text date">{activeText.date}</div>
          : null}
          {activeText.in ?
            <div className="item--text in">{activeText.in}</div>
          : null}
          {activeText.translate ?
            <div className="item--text translate">{activeText.translate}</div>
          : null}
          {activeText.notes ?
            <ReactMarkdown className="item--text notes">{activeText.notes}</ReactMarkdown>
          : null}
        </div>
      </div>

    </div>
  );
};

export default Texts;
