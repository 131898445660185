import React from 'react';
import './noImgPreview.scss';

const NoImgPreview = () => {

  return (
    <div className="container__preview--no--img">
      pas encore d'image(s)
    </div>
  );
};

export default NoImgPreview;
