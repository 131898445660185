import React from 'react';

const Cross = () => {

  return (
    <svg width="22px" height="22px" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <path d="M 10,10 L 30,30 M 30,10 L 10,30" stroke="rgb(126, 126, 126)" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Cross;
