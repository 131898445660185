import React from 'react';
import Navbar from '../Navbar';
import Input from '../Input';
import '../Header/header.scss';

const Header = (props) => {

  return (
    <header className="header">
      <Input closeMenu={props.closeMenu}/>
      <Navbar toggleMenu={props.toggleMenu}/>
    </header>
  );
};

export default Header;