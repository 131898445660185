import React from 'react';
import '../Navbar/navbar.scss';

const Navbar = (props) => {

  return (
    <div onClick={props.toggleMenu} className="menu--btn">menu</div>
  );
};

export default Navbar;
