import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import DataContext from '../../config/DataContext';
import ReactMarkdown from 'react-markdown';
import NoImgList from '../../components/NoImgList';
import './search.scss';

const Search = () => {
  const data = useContext(DataContext);
  const { searchParams } = useParams();
  const [worksToDisplay, setWorksToDisplay] = useState([]);
  const [textsToDisplay, setTextsToDisplay] = useState([]);
  const [soloExhibitionsToDisplay, setSoloExhibitionsToDisplay] = useState([]);
  const [groupExhibitionsToDisplay, setGroupExhibitionsToDisplay] = useState([]);
  const [bibliographiesToDisplay, setBibliographiesToDisplay] = useState([]);
  const [soloExhibitionsToDisplayYEARS, setSoloExhibitionsToDisplayYEARS] = useState([]);
  const [groupExhibitionsToDisplayYEARS, setGroupExhibitionsToDisplayYEARS] = useState([]);
  const [bibliographiesToDisplayYEARS, setBibliographiesToDisplayYEARS] = useState([]);

  // useEffect(() => {
  //   if (searchParams != undefined) {
  //     let arrSearchParams = searchParams.split(' ');
  //     handleFiltering(setWorksToDisplay, arrSearchParams, data.works)
  //     handleFiltering(setTextsToDisplay, arrSearchParams, data.texts)
  //     handleFiltering(setSoloExhibitionsToDisplay, arrSearchParams, data.solo_exhibitions)
  //     handleFiltering(setGroupExhibitionsToDisplay, arrSearchParams, data.group_exhibitions)
  //     handleFiltering(setBibliographiesToDisplay, arrSearchParams, data.bibliographies)
  //   }
  // }, [searchParams])

  // console.log(searchParams)

  // const handleFiltering = (setFunction, params, data) => {
  //   let filteredData = data.filter((item) => 
  //     // This can be upgraded to choose any values of the object 
  //     params.every((param) => JSON.stringify(item).toLowerCase().includes(param))
  //   )
  //   setFunction(filteredData)
  // }

  useEffect(() => {
    setWorksToDisplay(
      data.works
      .sort((a, b) => b.year - a.year)
      .filter((work) =>
        work.year?.toString().includes(searchParams)
        || work.name?.toLowerCase().includes(searchParams.toLowerCase())
        || work.subname?.toLowerCase().includes(searchParams.toLowerCase())
        || work.full_name?.toLowerCase().includes(searchParams.toLowerCase())
        || work.medium?.toLowerCase().includes(searchParams.toLowerCase())
        || work.size?.toLowerCase().includes(searchParams.toLowerCase())
        || work.location?.toLowerCase().includes(searchParams.toLowerCase())
        || work.description?.toLowerCase().includes(searchParams.toLowerCase())
        || work.edition?.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibition?.toLowerCase().includes(searchParams.toLowerCase())
        || work.link?.toLowerCase().includes(searchParams.toLowerCase())
        || work.date?.toLowerCase().includes(searchParams.toLowerCase())
        || work.direction?.toLowerCase().includes(searchParams.toLowerCase())
        || work.music?.toLowerCase().includes(searchParams.toLowerCase())
        || work.duration?.toLowerCase().includes(searchParams.toLowerCase())
        || work.photographs?.toLowerCase().includes(searchParams.toLowerCase())
        || work.work_tags[0]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.work_tags[1]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.work_tags[2]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.work_tags[3]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.work_tags[4]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.work_tags[5]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[0]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[1]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[2]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[3]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[4]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[5]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[6]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[7]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[8]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[9]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[10]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[11]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[12]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[13]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[14]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[15]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[16]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[17]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[18]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[19]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[20]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[21]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[22]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[23]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[24]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[25]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[26]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[27]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[28]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[29]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[30]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[31]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[32]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[33]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[34]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[35]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[36]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[37]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[38]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.works_link[39]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.texts_link[0]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.texts_link[1]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.texts_link[2]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.texts_link[3]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.texts_link[4]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.texts_link[5]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[0]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[1]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[2]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[3]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[4]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[5]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[6]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[7]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[8]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[9]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.exhibitions_link[10]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[0]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[1]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[2]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[3]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[4]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[5]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[6]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[7]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[8]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[9]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[10]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[11]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[12]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[13]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[14]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[15]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[16]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[17]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[18]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[19]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[20]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[21]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[22]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[23]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[24]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[25]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[26]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[27]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[28]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[29]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[30]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[31]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[32]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[33]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[34]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[35]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[36]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[37]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[38]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[39]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[40]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[41]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[42]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[43]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[44]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[45]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[46]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[47]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[48]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[49]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[50]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[51]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[52]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[53]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[54]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[55]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[56]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[57]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[58]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[59]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[60]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[61]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[62]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[63]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[64]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[65]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[66]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[67]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[68]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[69]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[70]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[71]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[72]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[73]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[74]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[75]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[76]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[77]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[78]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[79]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[80]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[81]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[82]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[83]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[84]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[85]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[86]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[87]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[88]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[89]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[90]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[91]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[92]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[93]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[94]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[95]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[96]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[97]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[98]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[99]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[100]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[101]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[102]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[103]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[104]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[105]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[106]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[107]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[108]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[109]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[110]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[111]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[112]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[113]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[114]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[115]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[116]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[117]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[118]?.caption.toLowerCase().includes(searchParams.toLowerCase())
        || work.medias[119]?.caption.toLowerCase().includes(searchParams.toLowerCase())
      )
    );
    setTextsToDisplay(
      data.texts
      .sort((a, b) => b.year - a.year)
      .filter((text) =>
        text.year && text.year.toString().includes(searchParams)
        || text.name && text.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.author && text.author.toLowerCase().includes(searchParams.toLowerCase())
        || text.description && text.description.toLowerCase().includes(searchParams.toLowerCase())
        || text.content && text.content.toLowerCase().includes(searchParams.toLowerCase())
        || text.notes && text.notes.toLowerCase().includes(searchParams.toLowerCase())
        || text.link && text.link.toLowerCase().includes(searchParams.toLowerCase())
        || text.location && text.location.toLowerCase().includes(searchParams.toLowerCase())
        || text.translate && text.translate.toLowerCase().includes(searchParams.toLowerCase())
        || text.date && text.date.toLowerCase().includes(searchParams.toLowerCase())
        || text.in && text.in.toLowerCase().includes(searchParams.toLowerCase())
        || text.subname && text.subname.toLowerCase().includes(searchParams.toLowerCase())
        || text.works_link[0]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.works_link[1]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.works_link[2]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.works_link[3]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.works_link[4]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.works_link[5]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.exhibitions_link[0]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.exhibitions_link[1]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.exhibitions_link[2]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.exhibitions_link[3]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.exhibitions_link[4]?.name.toLowerCase().includes(searchParams.toLowerCase())
        || text.exhibitions_link[5]?.name.toLowerCase().includes(searchParams.toLowerCase())
      )
    );
    setSoloExhibitionsToDisplay(
      data.solo_exhibitions
      .sort((a, b) => b.year - a.year)
      .filter((exhibition) =>
        exhibition.year && exhibition.year.toString().includes(searchParams)
        || exhibition.name && exhibition.name.toLowerCase().includes(searchParams.toLowerCase())
        || exhibition.description && exhibition.description.toLowerCase().includes(searchParams.toLowerCase())
        || exhibition.date && exhibition.date.toLowerCase().includes(searchParams.toLowerCase())
      )
    );
    setGroupExhibitionsToDisplay(
      data.group_exhibitions
      .sort((a, b) => b.year - a.year)
      .filter((exhibition) =>
        exhibition.year && exhibition.year.toString().includes(searchParams)
        || exhibition.name && exhibition.name.toLowerCase().includes(searchParams.toLowerCase())
        || exhibition.description && exhibition.description.toLowerCase().includes(searchParams.toLowerCase())
        || exhibition.date && exhibition.date.toLowerCase().includes(searchParams.toLowerCase())
      )
    );
    setBibliographiesToDisplay(
      data.bibliographies
      .sort((a, b) => b.year - a.year)
      .filter((bibliography) =>
        bibliography.year && bibliography.year.toString().includes(searchParams)
        || bibliography.name && bibliography.name.toLowerCase().includes(searchParams.toLowerCase())
        || bibliography.description && bibliography.description.toLowerCase().includes(searchParams.toLowerCase())
        || bibliography.link && bibliography.link.toLowerCase().includes(searchParams.toLowerCase())
        || bibliography.date && bibliography.date.toLowerCase().includes(searchParams.toLowerCase())
        || bibliography.bibliography_tag.name && bibliography.bibliography_tag.name.toLowerCase().includes(searchParams.toLowerCase())
      )
    );
  }, [searchParams]);

  useEffect(() => {
    setSoloExhibitionsToDisplayYEARS([...new Set(soloExhibitionsToDisplay.map(ex => ex.year))])
  }, [soloExhibitionsToDisplay])

  useEffect(() => {
    setGroupExhibitionsToDisplayYEARS([...new Set(groupExhibitionsToDisplay.map(ex => ex.year))])
  }, [groupExhibitionsToDisplay])

  useEffect(() => {
    setBibliographiesToDisplayYEARS([...new Set(bibliographiesToDisplay.map(ex => ex.year))])
  }, [bibliographiesToDisplay])

  return (
    <div className="container__switch--page">

      <div className="container__head with--padding">
        <div className="container__sticky">
          <h1 className="page__title uppercase">recherche: <span className="search--value">{searchParams}</span></h1>
          <h2 className="page__subtitle">
            {worksToDisplay.length + textsToDisplay.length + soloExhibitionsToDisplay.length + groupExhibitionsToDisplay.length + bibliographiesToDisplay.length >= 2 ? (worksToDisplay.length + textsToDisplay.length + soloExhibitionsToDisplay.length + groupExhibitionsToDisplay.length + bibliographiesToDisplay.length + " Résultats") : (worksToDisplay.length + textsToDisplay.length + soloExhibitionsToDisplay.length + groupExhibitionsToDisplay.length + bibliographiesToDisplay.length + " Résultat")} 
          </h2>
        </div>
      </div>

      {worksToDisplay.length + textsToDisplay.length + soloExhibitionsToDisplay.length + groupExhibitionsToDisplay.length + bibliographiesToDisplay.length >= 1 ? (
        <ul className="container__content--search">
          {worksToDisplay.length > 0 ? (
            <li className="container__result--item" key={uuidv4()}>
                <div className="item--title">
                  œuvres (<span className="nb__color--blue">{worksToDisplay.length}</span>)
                </div>
                <ul className={`item--content works ${worksToDisplay.length % 2 === 0 ? "modulo--2" : null}`}>
                  {
                    worksToDisplay
                    .sort((a, b) => b.number - a.number)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .sort((a, b) => b.year - a.year)
                    .map((work) =>
                      <Link to={`/oeuvre&id=${work.id}&titre=${work.slug}`} key={uuidv4()}>
                        <li key={uuidv4()} className="item works">
                          <div className="container__photo">
                            {work.medias[0] ? (
                              <img src={work.medias.sort((a, b) => a.name.localeCompare(b.name))[0].url} alt={`Alain Séchas ${work.name}`} />
                            ) : (
                              <NoImgList />
                            )}
                          </div>
                          <div className="container__infos">
                            <div className="container__sticky">
                              <div className="year">{work.year}</div>
                              <div className="name">
                                {work.name}
                                {work.number ? (<span> {work.number}</span>) : (null)}
                              </div>
                              {work.subname ? (
                                <div className="span--subname">{work.subname}</div>
                              ) : (null)}
                              <ul className="type">
                                {work.work_tags
                                  .sort((a, b) => a.order - b.order)
                                  .map((tag) => 
                                    <li key={uuidv4()}>
                                      {tag.name}
                                    </li>
                                  )
                                }
                              </ul>
                            </div>
                          </div>
                        </li>
                      </Link>
                    )
                  }
                </ul>
            </li>
          ) : (
            null
          )}
          {textsToDisplay.length > 0 ? (
            <li className="container__result--item" key={uuidv4()}>
              <div className="item--title">
                textes (<span className="nb__color--blue">{textsToDisplay.length}</span>)
              </div>
              <ul className="item--content texts">
                {textsToDisplay.map((text) =>
                  <Link to={`/texte&id=${text.id}&titre=${text.slug}`} key={uuidv4()}>                  
                    <li key={uuidv4()} className="item texts">
                      <div className="year">{text.year}</div>
                      <div className="name">{text.name}</div>
                      {text.subname ? (
                        <div className="span--subname">{text.subname}</div>
                      ) : (null)}
                      <div className="author">{text.author}</div>
                    </li>
                  </Link>
                )}
              </ul>
            </li>
          ) : (
            null
          )}
          {soloExhibitionsToDisplay.length > 0 ? (
            <li className="container__result--item" key={uuidv4()}>
              <div className="item--title">
                expositions personnelles (<span className="nb__color--blue">{soloExhibitionsToDisplay.length}</span>)
              </div>
              <ul className="item--content exhibitions">
                {soloExhibitionsToDisplayYEARS.map((currentYear) =>
                  <li className="container__each--year" key={uuidv4()}>
                    <div className="container__sticky">
                      <p className="year">
                        {currentYear}
                      </p>
                    </div>
                    <ul className="content">
                      {soloExhibitionsToDisplay
                        .filter(exhibition => exhibition.year === currentYear)
                        .map((exhibition) =>
                        <li key={uuidv4()} className="item exhibitions">
                          {exhibition.name ?
                            <div className="name">
                              {exhibition.work_link ? (
                                <Link to={`/oeuvre&id=${exhibition.work_link.id}&titre=${exhibition.work_link.slug}`}>
                                  {exhibition.name}
                                </Link>
                              ) : (
                                exhibition.name
                              )}
                            </div> 
                            : (null)
                          }
                          {exhibition.name == null && exhibition.work_link ? (
                            <Link to={`/oeuvre&id=${exhibition.work_link.id}&titre=${exhibition.work_link.slug}`}>
                              <ReactMarkdown className="description">{exhibition.description}</ReactMarkdown>
                            </Link>
                          ) : (
                            <ReactMarkdown className="description">{exhibition.description}</ReactMarkdown>
                          )}
                          <div className="date">{exhibition.date}</div>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </ul>
            </li>
          ) : (
            null
          )}
          {groupExhibitionsToDisplay.length > 0 ? (
            <li className="container__result--item" key={uuidv4()}>
              <div className="item--title">
                expositions collectives (<span className="nb__color--blue">{groupExhibitionsToDisplay.length}</span>)
              </div>
              <ul className="item--content exhibitions">
                {groupExhibitionsToDisplayYEARS.map((currentYear) =>
                  <li className="container__each--year" key={uuidv4()}>
                    <div className="container__sticky">
                      <p className="year">
                        {currentYear}
                      </p>
                    </div>
                    <ul className="content">
                      {groupExhibitionsToDisplay
                        .filter(exhibition => exhibition.year === currentYear)
                        .map((exhibition) =>
                        <li key={uuidv4()} className="item exhibitions">
                          {exhibition.name ?
                            <div className="name">
                              {exhibition.work_link ? (
                                <Link to={`/oeuvre&id=${exhibition.work_link.id}&titre=${exhibition.work_link.slug}`}>
                                  {exhibition.name}
                                </Link>
                              ) : (
                                exhibition.name
                              )}
                            </div> 
                            : (null)
                          }
                          {exhibition.name == null && exhibition.work_link ? (
                            <Link to={`/oeuvre&id=${exhibition.work_link.id}&titre=${exhibition.work_link.slug}`}>
                              <ReactMarkdown className="description">{exhibition.description}</ReactMarkdown>
                            </Link>
                          ) : (
                            <ReactMarkdown className="description">{exhibition.description}</ReactMarkdown>
                          )}
                          <div className="date">{exhibition.date}</div>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </ul>
            </li>
          ) : (
            null
          )}
          {bibliographiesToDisplay.length > 0 ? (
            <li className="container__result--item" key={uuidv4()}>
              <div className="item--title">
                éléments bibliographique (<span className="nb__color--blue">{bibliographiesToDisplay.length}</span>)
              </div>
              <ul className="item--content exhibitions">
                {bibliographiesToDisplayYEARS.map((currentYear) =>
                  <li className="container__each--year" key={uuidv4()}>
                    <div className="container__sticky">
                      <p className="year">
                        {currentYear}
                      </p>
                    </div>
                    <ul className="content">
                      {bibliographiesToDisplay
                        .filter(bibliography => bibliography.year === currentYear)
                        .map((bibliography) =>
                        <li key={uuidv4()} className="item exhibitions">
                          {bibliography.name != null && bibliography.work_link == null && bibliography.text_link == null &&
                            <div className="name">
                              {bibliography.name}
                            </div>
                          }
                          {bibliography.name != null && bibliography.work_link != null && bibliography.text_link == null &&
                            <div className="name">
                              <Link to={`/oeuvre&id=${bibliography.work_link.id}&titre=${bibliography.work_link.slug}`}>
                                {bibliography.name}
                              </Link>
                            </div>
                          }
                          {bibliography.name != null && bibliography.work_link == null && bibliography.text_link != null &&
                            <div className="name">
                              <Link to={`/texte&id=${bibliography.text_link.id}&titre=${bibliography.text_link.slug}`}>
                                {bibliography.name}
                              </Link>
                            </div>
                          }
                          {bibliography.name == null && bibliography.work_link == null && bibliography.text_link == null &&
                            <div className="name undefined">
                              untilted
                            </div>
                          }
                          {bibliography.name == null && bibliography.work_link != null && bibliography.text_link == null &&
                            <div className="name undefined">
                              <Link to={`/oeuvre&id=${bibliography.work_link.id}&titre=${bibliography.work_link.slug}`}>
                                untilted
                              </Link>
                            </div>
                          }
                          {bibliography.name == null && bibliography.work_link == null && bibliography.text_link != null &&
                            <div className="name undefined">
                              <Link to={`/texte&id=${bibliography.text_link.id}&titre=${bibliography.text_link.slug}`}>
                                untilted
                              </Link>
                            </div>
                          }
                          <ReactMarkdown className="description">{bibliography.description}</ReactMarkdown>
                          <ReactMarkdown className="link" linkTarget="_blank">{bibliography.link}</ReactMarkdown>
                          <div className="date">{bibliography.date}</div>
                          <div className="type">{bibliography.bibliography_tag.name}</div>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </ul>
            </li>
          ) : (
            null
          )}
        </ul>
      ) : (
        <div className="container__error--noFound">
          Il n'y a aucun résultat qui correspond à votre recherche.<br/>
          Vous pouvez affiner votre recherche ou <Link to="/" className="link--uppercase">retourner à l'accueil</Link>.
        </div>
      )}
    </div>
  );
};

export default Search;
