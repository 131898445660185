import React, { useEffect, useState, useContext } from "react";
import DataContext from "../../config/DataContext";
import { useParams } from 'react-router';
import { useHistory, Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PreviewImgs from "../../components/PreviewImgs";
import NoImgList from "../../components/NoImgList";

const Index = () => {
  const data = useContext(DataContext);
  const history = useHistory();
  const { yearId } = useParams();
  const { tagId } = useParams();
  const works = data.works.sort((a, b) => b.year - a.year);
  const workTags = data.work_tags.sort((a, b) => a.order - b.order);
  const highlights = data.highlights;
  const [worksToDisplay, setWorksToDisplay] = useState([]);
  const [isActiveHighlights, setIsActiveHighlights] = useState(true);
  const [activeWork, setActiveWork] = useState([]);

  useEffect(() => {
    data.resetInputValue();
  }, []);

  useEffect(() => {
    if (tagId == "*" && yearId == "*") {
      setIsActiveHighlights(true)
      setWorksToDisplay(works)
    } else if (tagId == "*" && yearId != "*") {
      setIsActiveHighlights(false)
      setWorksToDisplay(works.filter((work) => work.year == yearId))
    } else if (tagId != "*" && yearId == "*") {
      setIsActiveHighlights(false)
      setWorksToDisplay(
        works.filter((work) => work.work_tags[0]?.order == tagId
        || work.work_tags[1]?.order == tagId
        || work.work_tags[2]?.order == tagId
        || work.work_tags[3]?.order == tagId
        || work.work_tags[4]?.order == tagId
        || work.work_tags[5]?.order == tagId
        )
      )
    }
  }, [tagId, yearId]);

  const handleTagURL = (tag) => {
    if (tag == tagId) {
      history.push(`/&categorie=*&annee=*`);
    } else {
      history.push(`/&categorie=${tag}&annee=*`);
    }
  };

  const handleYearURL = (year) => {
    if (year == yearId) {
      history.push(`/&categorie=*&annee=*`);
    } else {
      history.push(`/&categorie=*&annee=${year}`);
    }
  };
  
  return (
    <div className="container__switch--page grid--2">
      <div className="section--left work--index">

        <div className="container__head">
          <div className="container__sticky">
            <h1 className="page__title uppercase">Alain Séchas</h1>
            <div className="container__filter">
              {
                workTags
                .map((tag) => 
                  <button
                    key={uuidv4()}
                    className={tag.order == tagId ? "filter--btn active" : "filter--btn"}
                    onClick={() => handleTagURL(tag.order)}
                  >
                    {tag.name}
                  </button>
                )
              }
            </div>
            <div className="container__filter last">
              {
                [...new Set(works.map(ex => ex.year))]
                .map((year) => 
                  <button
                    key={uuidv4()}
                    className={year == yearId ? "filter--btn active" : "filter--btn"}
                    onClick={() => handleYearURL(year)}
                  >
                    {year}
                  </button>
                )
              }
            </div>
          </div>
        </div>

        {isActiveHighlights ? null : (
          <div className="container__content--works--texts index">
            <div className="nav">
              <div className="head--item">année</div>
              <div className="head--item">titre</div>
              <div className="head--item">catégorie</div>
            </div>
            <div className="content content--work--index">
              {worksToDisplay
                .sort((a, b) => b.number - a.number)
                .sort((a, b) => a.name.localeCompare(b.name))
                .sort((a, b) => b.year - a.year)
                .map((work) => (
                <Link
                  to={`/oeuvre&id=${work.id}&titre=${work.slug}`}
                  key={uuidv4()}
                  className="item"
                  onMouseEnter={() => setActiveWork(work)}
                  onMouseLeave={() => setActiveWork([])}
                >
                  <div className="item--year">
                    <div className="sticky--word">
                      {work.year}
                    </div>
                  </div>
                  <div className="item--title">
                    {work.name}
                    {work.number ? (<span> {work.number}</span>) : (null)}
                    {work.subname ? 
                      (<div className="span--subname"> {work.subname}</div>) 
                      : (null)
                    }
                  </div>
                  <div className="item--types">
                    {
                      work.work_tags
                      .sort((a, b) => a.order - b.order)
                      .map((tag) => (
                        <div className="type" key={uuidv4()}>
                          {tag.name}
                        </div>
                      ))
                    }
                  </div>
                  <div className="container__item--photo">
                    <div className="container__item--img--mobile">
                      {work.medias[0] ? (
                        <img src={work.medias[0].url} alt={`Alain Séchas ${work.name}`} />
                      ) : (
                        <NoImgList />
                      )}
                    </div>
                    <div className="container__item--content--mobile">
                      <div className="container__sticky">
                        <div className="item--year--mobile">{work.year}</div>
                        <div className="item--title--mobile">
                          {work.name}
                          {work.number ? (<span> {work.number}</span>) : (null)}
                        </div>
                        {work.subname ? (
                          <div className="span--subname">{work.subname}</div>
                        ) : (null)}
                        <div className="item--types--mobile">
                          {work.work_tags
                            .sort((a, b) => a.order - b.order)
                            .map((tag) => (
                              <div className="type" key={uuidv4()}>
                                {tag.name}
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className={isActiveHighlights ? "section--right work--index" : "section--right work--index annex"}>
        {isActiveHighlights ? (
          <ul className="container__highlights">
            {highlights
              .sort((a, b) => a.order - b.order)
              .map((highlight) => (
                <li key={uuidv4()}>
                  <Link
                    to={`/oeuvre&id=${highlight.work.id}&titre=${highlight.work.slug}`}
                    key={uuidv4()}
                    className="item--highlight"
                  >
                    <img
                      src={`${highlight.work.medias.sort((a, b) => a.name.localeCompare(b.name))[0].url}`}
                      alt={`Alain Séchas œuvre ${highlight.work.name}`}
                      className="item--img"
                    />
                    <div className="item--infos">
                      <div className="title">
                        {highlight.work.name}
                        {highlight.work.number ? (<span> {highlight.work.number}</span>) : (null)}
                      </div>
                      <div className="year">{highlight.work.year}</div>
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        ) : <PreviewImgs activeWork={activeWork} />}
      </div>
    </div>
  );
};

export default Index;
