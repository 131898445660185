import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import { fetchData } from './config/fetchData';
import DataContext from './config/DataContext';
import ReactDOM from 'react-dom';
import './styles/reset.scss';
import './styles/main.scss';
import './styles/head.scss';
import './styles/contentExhibitions.scss';
import './styles/contentBibliographies.scss';
import './styles/contentWorksTexts.scss';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import Header from './components/Header';
import Works from './Pages/Works';
import Work from './Pages/Works/Work';
import Texts from './Pages/Texts';
import Text from './Pages/Texts/Text';
import Bibliography from './Pages/Bibliography';
import Solo_Exhibitions from './Pages/Solo_Exhibitions';
import Group_Exhibitions from './Pages/Group_Exhibitions';
import Error404 from './Pages/Error404';
import Menu from './components/Menu';
import Search from './Pages/Search';

const App = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [homeIsActive, setHomeIsActive] = useState(true);
  const [works, setWorks] = useState([]);
  const [work_tags, setWorkTags] = useState([]);
  const [texts, setTexts] = useState([]);
  const [solo_exhibitions, setSoloExhibitions] = useState([]);
  const [group_exhibitions, setGroupExhibitions] = useState([]);
  const [bibliographies, setBibliographies] = useState([]);
  const [bibliography_tags, setBibliographyTags] = useState([]);
  const [galeries, setGaleries] = useState([]);
  const [friends, setFriends] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [informations, setInformations] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    fetchData(setWorks, "/works")
    fetchData(setWorkTags, "/work-tags")
    fetchData(setTexts, "/texts")
    fetchData(setSoloExhibitions, "/solo-exhibitions")
    fetchData(setGroupExhibitions, "/group-exhibitions")
    fetchData(setBibliographies, "/bibliographies")
    fetchData(setBibliographyTags, "/bibliography-tags")
    fetchData(setGaleries, "/galeries")
    fetchData(setFriends, "/friends")
    fetchData(setHighlights, "/highlights")
    fetchData(setInformations, "/informations")
  }, []);

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  const closeMenu = () => {
    setIsMenuActive(false);
  }

  const toggleHome = () => {
    setHomeIsActive(!homeIsActive);
  }

  return (
    <DataContext.Provider
      value={{
        works: works,
        work_tags: work_tags,
        texts: texts,
        solo_exhibitions: solo_exhibitions,
        group_exhibitions: group_exhibitions,
        bibliographies: bibliographies,
        bibliography_tags: bibliography_tags,
        galeries: galeries,
        friends: friends,
        highlights: highlights,
        informations: informations,
        filterInput: {value: inputValue},
        setInputValue: (e) => setInputValue(e.target.value),
        resetInputValue: () => setInputValue("")
      }}>
      <Router>
        {works               == false 
        || work_tags         == false
        || texts             == false
        || solo_exhibitions  == false
        || group_exhibitions == false
        || bibliographies    == false
        || bibliography_tags == false
        || galeries          == false
        || friends           == false
        || highlights        == false
        || informations      == false ? (
          <Home
            works={works}
            work_tags={work_tags}
            texts={texts}
            solo_exhibitions={solo_exhibitions}
            group_exhibitions={group_exhibitions}
            bibliographies={bibliographies}
            bibliography_tags={bibliography_tags}
            galeries={galeries}
            friends={friends}
            highlights={highlights}
            informations={informations}
            homeIsActive={homeIsActive}
            toggleHome={toggleHome}
          />
          ) : (
            <>
              <Home
                works={works}
                work_tags={work_tags}
                solo_exhibitions={solo_exhibitions}
                group_exhibitions={group_exhibitions}
                bibliographies={bibliographies}
                bibliography_tags={bibliography_tags}
                galeries={galeries}
                friends={friends}
                highlights={highlights}
                informations={informations}
                homeIsActive={homeIsActive}
                toggleHome={toggleHome}
                closeMenu={closeMenu}
              />
              <ScrollMemory />
              <div className="container__fixed--all">
                <Header toggleMenu={toggleMenu} closeMenu={closeMenu} />
                <main
                  className="main__content"
                  onClick={closeMenu}
                >
                  <Menu
                    isMenuActive={isMenuActive}
                    toggleMenu={toggleMenu}
                    toggleHome={toggleHome}
                  />
                  <Switch>
                    <Route path="/" exact render={() => <Redirect to="/&categorie=*&annee=*" />}/>
                    <Route path="/&categorie=:tagId&annee=:yearId" render={() => <Works />}/>
                    <Route path="/recherche=:searchParams" render={() => <Search />}/>
                    <Route path="/oeuvre&id=:workId&titre=:workSlug" render={() => <Work />}/>
                    <Route path="/textes&annee=:yearId" exact render={() => <Texts />}/>
                    <Route path="/texte&id=:textId&titre=:textSlug" render={() => <Text />}/>
                    <Route path="/bibliographie&categorie=:tagId&annee=:yearId" render={() => <Bibliography />}/>
                    <Route path="/expositions-personnelles&annee=:yearId" render={() => <Solo_Exhibitions />}/>
                    <Route path="/expositions-collectives&annee=:yearId" render={() => <Group_Exhibitions />}/>
                    <Route path="/contact" render={() => <Contact />}/>
                    <Route path="*" render={() => <Error404 />}/>
                  </Switch>
                </main>
              </div>
            </>
          )}
      </Router>
    </DataContext.Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
