import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import DataContext from '../../config/DataContext';
import '../Input/input.scss';
import ArrowNext from '../../assets/svg/ArrowNext';

const Input = (props) => {
  const data = useContext(DataContext);
  const history = useHistory();
  
  const handleSubmit = (e, value) => {
    if (value.length <= 2 && e.keyCode === 13) {
      data.resetInputValue();
      props.closeMenu();
    } else if (value.length >= 3 && e.keyCode === 13) {
      history.push(`/recherche=${value}`);
      props.closeMenu();
    }
  }

  const handleClickSearch = () => {
    history.push(`/recherche=${data.filterInput.value}`);
  }

  const handleGoBack = () => {
    props.closeMenu();
    history.goBack();
  }

  return (
    <div className="container__search--input">
      <button
        onClick={handleGoBack}
        className="go--back">
        retour
      </button>
      <input
        type="text"
        placeholder="recherche…"
        value={data.filterInput.value}
        onChange={data.setInputValue}
        onKeyDown={(e) => handleSubmit(e, data.filterInput.value)}
        autoComplete="off"
        id="search--input"
      />
      {data.filterInput.value.length >= 3 ? (
        <div
          className="container--cta"
          onClick={handleClickSearch}
        >
          <ArrowNext />
        </div>
      ) : null}
    </div>
  );
};

export default Input;