import React, { useEffect } from 'react';
// import { useHistory } from "react-router-dom";
import LogoHome from '../../components/LogoHome';
import Loader from '../../components/Loader';
import '../Home/home.scss';
import anime from "animejs";

const Home = ({ 
    works,
    work_tags,
    texts,
    solo_exhibitions,
    group_exhibitions,
    bibliographies,
    bibliography_tags,
    galeries,
    highlights,
    informations,
    homeIsActive,
    toggleHome,
    closeMenu
  }) => {
  // const history = useHistory();

  useEffect(() => {
    const animationTimeline = anime.timeline();

    animationTimeline
      .add({
        targets: '#logoContainer',
        duration: 2000,
        translateY: ['-110%','0%'],
        easing: 'easeOutExpo',
        delay: 400
      })
      .add({
        targets: '#asideMenu',
        duration: 1000,
        translateX: ['110%','0%'],
        easing: 'easeOutExpo',
      }, '-=800')
      .add({
        targets: '#bottomContainer',
        duration: 1000,
        translateY: ['110%','0%'],
        easing: 'easeOutExpo',
      }, '-=600')
      .add({
        targets: '#loaderCTA',
        opacity: [0, 100]
      }, '-=150')
      .add({
        targets: '#openCTA',
        opacity: [0, 100]
      }, '-=600')
  }, []);

  const handleDiscover = () => {
    toggleHome();
    // history.push(``);
  }

  return (
    <div className={homeIsActive ? "home__slider--left active" : "home__slider--left"} id="containerMenu" onClick={closeMenu}>
      <div className="main">
        <div className="logo" id="logoContainer">
          <LogoHome />
        </div>
        <div className="container__cta">
          <div className="cta--overflow">            
            {works               == false 
            || work_tags         == false
            || texts             == false
            || solo_exhibitions  == false
            || group_exhibitions == false
            || bibliographies    == false
            || bibliography_tags == false
            || galeries          == false
            || highlights        == false
            || informations      == false ? (
              <Loader />
            ) : (
              <div className="cta" onClick={handleDiscover} id="openCTA">
                ouvrir
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bottom" id="bottomContainer">
        <div className="left">© ALAIN SÉCHAS 2021, TOUS DROITS RÉSERVÉS</div>
        <div className="right">
          <div className="mail">
            <a
              href="mailto:info@alainsechas.com"
            >
              mail
            </a>
          </div>
          <div className="instagram">
            <a
              href="https://www.instagram.com/alainsechas/"
              target="_blank"
            >
              @alainsechas
            </a>
          </div>
        </div>
      </div>
      {works               == false 
        || work_tags         == false
        || texts             == false
        || solo_exhibitions  == false
        || group_exhibitions == false
        || bibliographies    == false
        || bibliography_tags == false
        || galeries          == false
        || highlights        == false
        || informations      == false ? (
          <div
            className="aside"
            id="asideMenu"
          >
            accueil
          </div>
        ) : (
          <div
            className="aside"
            id="asideMenu"
            onClick={homeIsActive ? handleDiscover : toggleHome}
          >
            accueil
          </div>
        )}
    </div>
  );
};

export default Home;
