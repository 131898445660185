import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory, Link } from "react-router-dom";
import DataContext from '../../config/DataContext';
import ReactMarkdown from 'react-markdown';
import { v4 as uuidv4 } from 'uuid';

const Group_Exhibitions = () => {
  const data = useContext(DataContext);
  const history = useHistory();
  const { yearId } = useParams();
  const groupExhibitions = data.group_exhibitions.sort((a, b) => b.year - a.year);
  const [groupExhibitionsToDisplay, setGroupExhibitionsToDisplay] = useState([]);

  useEffect(() => {
    data.resetInputValue();
  }, []);

  useEffect(() => {
    if (yearId == "*") {
      setGroupExhibitionsToDisplay(groupExhibitions)
    } else {
      setGroupExhibitionsToDisplay(groupExhibitions.filter((exhibition) => exhibition.year == yearId))
    }
  }, [yearId]);

  const handleURL = (year) => {
    if (year == yearId) {
      history.push(`/expositions-collectives&annee=*`);
    } else {
      history.push(`/expositions-collectives&annee=${year}`);
    }
  };

  return (
    <div className="container__switch--page">

      <div className="container__head">
        <div className="container__sticky">
          <h1 className="page__title uppercase">expositions collectives</h1>
          <div className="container__filter width--50 last">
            {
              [...new Set(groupExhibitions.map(ex => ex.year))]
              .map((year) =>
                <button
                  className={year == yearId ? "filter--btn active" : "filter--btn"}
                  key={uuidv4()}
                  onClick={() => handleURL(year)}
                >
                  {year}
                </button>
              )
            }
          </div>
        </div>
      </div>

      <div className="container__content--exhibitions">
        {
          [...new Set(groupExhibitionsToDisplay.map(ex => ex.year))]
          .map((currentYear) =>
            <div className="container__each--year" key={uuidv4()}>
              <div className="container--year">
                <div className="year">{currentYear}</div>
              </div>
              <div className="content">
                {
                  groupExhibitionsToDisplay
                  .filter(exhibition => exhibition.year === currentYear)
                  .map((filteredExhibition) =>
                    <div
                      className="content__item"
                      key={uuidv4()}
                    >
                      {filteredExhibition.name ?
                        <div className="name">
                          {filteredExhibition.work_link ? (
                            <Link to={`/oeuvre&id=${filteredExhibition.work_link.id}&titre=${filteredExhibition.work_link.slug}`}>
                              {filteredExhibition.name}
                            </Link>
                          ) : (
                            filteredExhibition.name
                          )}
                        </div> 
                        : (null)
                      }
                      {filteredExhibition.name == null && filteredExhibition.work_link? (
                        <Link to={`/oeuvre&id=${filteredExhibition.work_link.id}&titre=${filteredExhibition.work_link.slug}`}>
                          <ReactMarkdown className="description">{filteredExhibition.description}</ReactMarkdown>
                        </Link>
                      ) : (
                        <ReactMarkdown className="description">{filteredExhibition.description}</ReactMarkdown>
                      )}
                      <div className="date">{filteredExhibition.date}</div>
                    </div>
                  )
                }
              </div>
            </div>
          )
        }
      </div>
    </div>    
  );
};

export default Group_Exhibitions;
