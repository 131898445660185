import React from 'react';
import './noImgList.scss';

const NoImgList = () => {

  return (
    <div className="container__preview--no--img list">
      pas encore d'image(s)
    </div>
  );
};

export default NoImgList;
