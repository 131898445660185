import React, { useContext, useEffect, useState } from 'react';
import DataContext from '../../config/DataContext';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactMarkdown from 'react-markdown';
import Error404 from '../Error404';

const Text = () => {
  const { textId } = useParams();
  const data = useContext(DataContext);
  const text = data.texts.find(text => text.id == textId);
  const sortedTexts = data.texts.sort((a, b) => b.year - a.year);
  const [nextText, setNextText] = useState();
  const [previousText, setPreviousText] = useState();
  
  useEffect(() => {
    data.resetInputValue();
  }, [])

  useEffect(() => {
    findPreviousText();
    findNextText();
  }, [textId])
  
  const findPreviousText = () => {
    const indexOfCurrentText = sortedTexts.indexOf(text);
    if (indexOfCurrentText === 0) {
      setPreviousText(sortedTexts[sortedTexts.length -1])
    } else {
      setPreviousText(sortedTexts[indexOfCurrentText - 1])
    };
  };

  const findNextText = () => {
    const indexOfCurrentText = sortedTexts.indexOf(text);
    if (indexOfCurrentText === sortedTexts.length - 1) {
      setNextText(sortedTexts[0])
    } else {
      setNextText(sortedTexts[indexOfCurrentText + 1])
    };
  };

  if (text == undefined) {
    return (
      <Error404 />
    )
  }

  return (
    <div className="container__switch--page grid--2">

      <div className="section--left item">
        <div className="container__head with--padding">
          <div className="container__sticky">
            <h1 className="page__title">{text.name}</h1>
            {text.subname ? (
              <h2 className="page__subtitle">{text.subname}</h2>
            ) : (null)}
            {text.year ?
              <div className="container__work--type">
                <Link to={`/textes&annee=${text.year}`}>
                  <div
                    className="work--year"
                  >
                    {text.year}
                  </div>
                </Link>
              </div>
            : null}
          </div>
        </div>
        <div className="container__content--works--texts">
          <div className="content not--index">
            {text.author ?
              <div className="show-item">
                <div className="label">
                  <div className="sticky--work">
                    auteur(e)
                  </div>
                </div>
                <div className="content-md uppercase">{text.author}</div>
              </div>
            : null}
            {text.location ?
              <div className="show-item">
                <div className="label">
                  <div className="sticky--work">
                    lieu
                  </div>
                </div>
                <ReactMarkdown className="content-md">{text.location}</ReactMarkdown>
              </div>
            : null}
            {text.description ?
              <div className="show-item">
                <div className="label">
                  <div className="sticky--work">
                    description
                  </div>
                </div>
                <ReactMarkdown className="content-md">{text.description}</ReactMarkdown>
              </div>
            : null}
            {text.link ?
              <div className="show-item">
                <div className="label">
                  <div className="sticky--work">
                    liens
                  </div>
                </div>
                <ReactMarkdown className="content-md link" linkTarget="_blank">{text.link}</ReactMarkdown>
              </div>
            : null}
            {text.exhibitions_link.length >= 1 ?
              <div className="show-item">
                <div className="label">
                  <div className="sticky--work">
                    {text.exhibitions_link.length === 1 ? "exposition associée" : "expositions associées"}
                  </div>
                </div>
                <ul className="content">
                  {text.exhibitions_link
                    .sort((a, b) => a.number - b.number)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .sort((a, b) => b.year - a.year)
                    .map((linkedWork) =>
                    <li className="type" key={uuidv4()}>
                      <Link to={`/oeuvre&id=${linkedWork.id}&titre=${linkedWork.slug}`}>
                        {linkedWork.name} {linkedWork.number ? (<span> {linkedWork.number}</span>) : (null)} ({linkedWork.year})
                      </Link>
                    </li>)
                  }
                </ul>
              </div>
            : null}
            {text.works_link.length >= 1 ?
              <div className="show-item">
                <div className="label">
                  <div className="sticky--work">
                    {text.works_link.length === 1 ? "œuvre associée" : "œuvres associées"}
                  </div>
                </div>
                <ul className="content">
                  {text.works_link
                    .sort((a, b) => a.number - b.number)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .sort((a, b) => b.year - a.year)
                    .map((linkedWork) =>
                      <li className="type" key={uuidv4()}>
                        <Link to={`/oeuvre&id=${linkedWork.id}&titre=${linkedWork.slug}`}>
                          {linkedWork.name} {linkedWork.number ? (<span> {linkedWork.number}</span>) : (null)} ({linkedWork.year})
                        </Link>
                      </li>
                    )
                  }
                </ul>
              </div>
            : null}
          </div>
        </div>
        {/* <div className="nav--bottom">
          {nextText ? (
            <Link className="item--previous" to={`/textes&id=${nextText.id}&titre=${nextText.slug}`}>
              texte précédent
            </Link>
          ) : (
            null
          )}
          {previousText ? (
            <Link className="item--next" to={`/textes&id=${previousText.id}&titre=${previousText.slug}`}>
              texte suivant
            </Link>
          ) : (
            null
          )}
        </div> */}
      </div>

      <div className="section--right text">
        <div className="container__all--texts">
          {text.content ?
            <ReactMarkdown className="item--text content">{text.content}</ReactMarkdown>
            : null}
          {text.date ?
            <div className="item--text date">{text.date}</div>
          : null}
          {text.in ?
            <div className="item--text in">{text.in}</div>
          : null}
          {text.translate ?
            <div className="item--text translate">{text.translate}</div>
          : null}
          {text.notes ?
            <ReactMarkdown className="item--text notes">{text.notes}</ReactMarkdown>
          : null}
        </div>
      </div>

    </div>
  );
};

export default Text;
