import React, { useContext, useEffect } from 'react';
import DataContext from '../../config/DataContext';
import '../Contact/contact.scss';
import ReactMarkdown from 'react-markdown';
import { v4 as uuidv4 } from 'uuid';

const Contact = () => {
  const data = useContext(DataContext);
  const galeries = data.galeries;
  const friends = data.friends;
  const informations = data.informations;
  const currentYear = new Date().getFullYear();
  const sortedGaleries = galeries.sort((a, b) => a.order - b.order);

  useEffect(() => {
    data.resetInputValue();
  }, [])

  return (
    <div className="container__switch--page contact">

      <div className="container__head contact">
        <div className="container__sticky">
          <h2 className="page__subtitle width--50">
            <ReactMarkdown className="description-md">{informations.description}</ReactMarkdown>
          </h2>
        </div>
      </div>

      <div className="container__content--contact">
        <div className="container__top">
          <div className="container__galeries">
            <div className="content">
              {sortedGaleries.map((galery) =>
                <div className="content__item" key={uuidv4()}>
                  <div className="name">{galery.name}</div>
                  <ReactMarkdown className="adress">{galery.adress}</ReactMarkdown>
                  <div className="telephone">{galery.telephone}</div>
                  <div className="mail"><a href={`mailto:${galery.mail}`}>email</a></div>
                  <ReactMarkdown className="link" linkTarget="_blank">{galery.link}</ReactMarkdown>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="container__bottom">
          <div className="friends">
            <div className="label">Autres contacts</div>
            <ul className="container__list">
              {friends.map((friend) =>
                <li className="item">
                  <ReactMarkdown linkTarget="_blank">
                    {friend.link}
                  </ReactMarkdown>
                </li>
              )}
            </ul>
          </div>
          <div className="copyright">{informations.copyright} {currentYear}, tous droits réservés</div>
          <div className="container__info--all">
            <div className="container__left">
              <div className="contact">
                <div className="mail">
                  Envoyer un <a href={`mailto:${informations.mail}`}>mail</a>
                </div>
                <div className="instagram">
                  Aller sur <a href={informations.instagram} target="_blank">instagram</a>
                </div>
              </div>
            </div>
            <div className="container__right">
              <div className="content">Design par <a href="https://benito.cool/" target="_blank">CURATED DEPARTMENT</a></div>
              <div className="content">Développement par <a href="https://benito.cool/" target="_blank">@benito.cool</a></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Contact;
