import { createContext } from 'react';

const DataContext = createContext({
  works: null,
  work_tags: null,
  texts: null,
  solo_exhibitions: null,
  group_exhibitions: null,
  bibliographies: null,
  bibliography_tags: null,
  galeries: null,
  friends: null,
  highlights: null,
  informations: null,
  filterInput: {value: ""},
  setInputValue: (e) => setInputValue(e.target.value),
  resetInputValue: () => setInputValue(""),
});

export default DataContext;
