import React from 'react';
import './loader.scss'

const Loader = () => {

  return (
    <div className="container__loader" id="loaderCTA">
      <p className="content--text">chargement</p>
    </div>
  );
};

export default Loader;
