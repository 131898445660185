import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory, Link } from "react-router-dom";
import DataContext from '../../config/DataContext';
import ReactMarkdown from 'react-markdown';
import { v4 as uuidv4 } from 'uuid';

const Bibliography = () => {
  const data = useContext(DataContext);
  const history = useHistory();
  const { yearId } = useParams();
  const { tagId } = useParams();
  const bibliographies = data.bibliographies.sort((a, b) => b.year - a.year);
  const bibliographyTags = data.bibliography_tags.sort((a, b) => a.year - b.year);
  const [bibliographiesToDisplay, setBibliographiesToDisplay] = useState([]);

  useEffect(() => {
    data.resetInputValue();
  }, []);

  useEffect(() => {
    if (tagId == "*" && yearId == "*") {
      setBibliographiesToDisplay(bibliographies)
    } else if (tagId == "*" && yearId != "*") {
      setBibliographiesToDisplay(bibliographies.filter((bibliographie) => bibliographie.year == yearId))
    } else if (tagId != "*" && yearId == "*") {
      setBibliographiesToDisplay(bibliographies.filter((bibliographie) => bibliographie.bibliography_tag.id == tagId))
    }
  }, [tagId, yearId]);

  const handleTagURL = (tag) => {
    if (tag == tagId) {
      history.push(`/bibliographie&categorie=*&annee=*`);
    } else {
      history.push(`/bibliographie&categorie=${tag}&annee=*`);
    }
  };

  const handleYearURL = (year) => {
    if (year == yearId) {
      history.push(`/bibliographie&categorie=*&annee=*`);
    } else {
      history.push(`/bibliographie&categorie=*&annee=${year}`);
    }
  };

  return (
    <div className="container__switch--page">

      <div className="container__head">
        <div className="container__sticky">
          <h1 className="page__title uppercase">bibliographie</h1>
          <div className="container__filter width--50">
            {
              bibliographyTags
              .map((tag) => 
                <button
                  key={uuidv4()}
                  className={tag.id == tagId ? "filter--btn active" : "filter--btn"}
                  onClick={() => handleTagURL(tag.id)}
                >
                  {tag.name}
                </button>
              )
            }
          </div>
          <div className="container__filter width--50 last">
            {
              [...new Set(bibliographies.map(ex => ex.year))]
              .map((year) => 
                <button
                  key={uuidv4()}
                  className={year == yearId ? "filter--btn active" : "filter--btn"}
                  onClick={() => handleYearURL(year)}
                >
                  {year}
                </button>
              )
            }
          </div>
        </div>
      </div>

      <div className="container__content--bibliographies">
        <div className="nav">
          <div className="head--item">année</div>
          <div className="head--item">titre</div>
          <div className="head--item">description</div>
          <div className="head--item">catégorie</div>
        </div>
        <ul className="content">
          {
            bibliographiesToDisplay
            .map((bibliography) =>
              <li className="item" key={uuidv4()}>
                <div className="item--year">
                  <div className="year">{bibliography.year}</div>
                </div>
                {bibliography.name != null && bibliography.work_link == null && bibliography.text_link == null &&
                  <div className="item--title">
                    {bibliography.name}
                  </div>
                }
                {bibliography.name != null && bibliography.work_link != null && bibliography.text_link == null &&
                  <div className="item--title">
                    <Link to={`/oeuvre&id=${bibliography.work_link.id}&titre=${bibliography.work_link.slug}`}>
                      {bibliography.name}
                    </Link>
                  </div>
                }
                {bibliography.name != null && bibliography.work_link == null && bibliography.text_link != null &&
                  <div className="item--title">
                    <Link to={`/texte&id=${bibliography.text_link.id}&titre=${bibliography.text_link.slug}`}>
                      {bibliography.name}
                    </Link>
                  </div>
                }
                {bibliography.name == null && bibliography.work_link == null && bibliography.text_link == null &&
                  <div className="item--title undefined">
                    untilted
                  </div>
                }
                {bibliography.name == null && bibliography.work_link != null && bibliography.text_link == null &&
                  <div className="item--title undefined">
                    <Link to={`/oeuvre&id=${bibliography.work_link.id}&titre=${bibliography.work_link.slug}`}>
                      untilted
                    </Link>
                  </div>
                }
                {bibliography.name == null && bibliography.work_link == null && bibliography.text_link != null &&
                  <div className="item--title undefined">
                    <Link to={`/texte&id=${bibliography.text_link.id}&titre=${bibliography.text_link.slug}`}>
                      untilted
                    </Link>
                  </div>
                }
                <div className="item--description">
                  <ReactMarkdown className="description">{bibliography.description}</ReactMarkdown>
                  <ReactMarkdown className="link" linkTarget="_blank">{bibliography.link}</ReactMarkdown>
                  <div className="date">{bibliography.date}</div>
                </div>
                <div className="item--type">{bibliography.bibliography_tag.name}</div>
              </li>
            )
          }
        </ul>
      </div>
    </div>    
  );
};

export default Bibliography;
