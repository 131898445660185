import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import '../Menu/menu.scss';
import DataContext from '../../config/DataContext';

const Menu = (props) => {
  const data = useContext(DataContext);
  const informations = data.informations;

  const toggleMenuAndHome = () => {
    props.toggleMenu();
    props.toggleHome();
  }

  return (
    <nav className={props.isMenuActive ? "container__menu " : "container__menu disabled"}>
      <ul>
        <li onClick={props.toggleMenu}><Link to="/">œuvres</Link></li>
        <li onClick={props.toggleMenu}><Link to="/textes&annee=*">textes</Link></li>
        <li onClick={props.toggleMenu}><Link to="/expositions-personnelles&annee=*">expositions personnelles</Link></li>
        <li onClick={props.toggleMenu}><Link to="/expositions-collectives&annee=*">expositions collectives</Link></li>
        <li onClick={props.toggleMenu}><Link to="/bibliographie&categorie=*&annee=*">bibliographie</Link></li>
        <li onClick={props.toggleMenu}><Link to="/contact">contacts</Link></li>
        <li onClick={toggleMenuAndHome} className="home--link">accueil</li>
        <li>
          <div className="contact">
            <div className="mail">
              Envoyer un <a href={`mailto:${informations.mail}`} className="no--block">mail</a>
            </div>
            <div className="instagram">
              Aller sur <a href={informations.instagram} target="_blank" className="no--block">instagram</a>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default Menu;
