import React from "react";
import './videoEmbed.scss';

const VideoEmbed = ({ video }) => {

  const createMarkup = () => {
    return {__html: video};
  }

  return (
    <div
      className="container__video"
      dangerouslySetInnerHTML={createMarkup()}
    />
  );
};

export default VideoEmbed;
